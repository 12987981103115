import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
  TimePicker,
} from 'antd';
import React, {useEffect, useState} from 'react';
// import formSchema from './dummy2.json';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {CustomDispatch} from '../../../helpers';
import {
  getFormUiRequest,
  getPayloadRequest,
  submitFormRequest,
} from '../../../redux/slicers/form';
import {
  getMachineStatusRequest,
  getMdlValuesRequest,
  getReasonCodesRequest,
  getRNsRequest,
  getSaltLevelsRequest,
  getShiftsRequest,
  getStatusRequest,
  getTechniciansRequest,
  getTotalChlorineValuesRequest,
  getWaterSoftenerValuesRequest,
} from '../../../redux/slicers/general';
import {Icons} from '../../../theme';
import {inputFieldRule, toastAlert} from '../../../utils';
import Loader from '../../loader';
import './styles.scss';
import OTPSelect from '../OtpSelect';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
const StepsFormTest2 = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  //STATES
  const [completedSteps, setCompletedSteps] = useState([]);
  const [payload, setPayload] = useState({});
  const [isAnyFieldFilled, setIsAnyFieldFilled] = useState(false);
  const [isStepCompleted, setIsStepCompleted] = useState(false); // New flag
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  //CONST VALS
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {id} = useParams();

  //CUSTOM DISPATCH
  const [getTechnicians, techniciansLoader] = CustomDispatch(
    getTechniciansRequest,
  );
  const [getRNs, rnLoader] = CustomDispatch(getRNsRequest);
  const [getShifts, shiftsLoader] = CustomDispatch(getShiftsRequest);
  const [getPayload, payloadLoader] = CustomDispatch(getPayloadRequest);
  const [submitForm, submitFormLoader] = CustomDispatch(submitFormRequest);
  const [getFormUi, uiLoader] = CustomDispatch(getFormUiRequest);
  const [getTotalChlorineValues, totalChlorineValuesLoader] = CustomDispatch(
    getTotalChlorineValuesRequest,
  );
  const [getWaterSoftenerValues, waterSoftenerValuesLoader] = CustomDispatch(
    getWaterSoftenerValuesRequest,
  );
  const [getMdlResultValues, mdlValuesLoader] =
    CustomDispatch(getMdlValuesRequest);
  const [getReasonCodes, reasonCodesLoader] = CustomDispatch(
    getReasonCodesRequest,
  );
  const [getSaltLevels, saltLevelsLoader] =
    CustomDispatch(getSaltLevelsRequest);
  const [getMachineStatus, machineStatusLoader] = CustomDispatch(
    getMachineStatusRequest,
  );
  const [getStatus, statusLoader] = CustomDispatch(getStatusRequest);
  //REDUX DATA
  const {
    technicians,
    rnList,
    totalChlorineValues,
    waterSoftenerValues,
    mdlValues,
    reasonCodes,
    saltLevels,
    machineStatus,
    status,
    shifts,
  } = useSelector(state => state.general);
  const {formPayload} = useSelector(state => state.form);
  const {formsData, isOnline, formsUi} = useSelector(state => state.offline);
  const formSchema = useSelector(state =>
    isOnline
      ? state.form.formUiJson || state.offline.formsUi?.[id]
      : state.offline.formsUi?.[id],
  );

  //HANDLERS

  const checkCompletedSteps = async () => {
    await createPayload(); // Ensure the payload is created first
    const values = form.getFieldsValue();

    const completed = formSchema?.steps
      ?.map(step => {
        const isStepCompleted = step.procedures.every(procedure => {
          return procedure.fields.every(field => {
            if (field.type === 'checkbox') {
              // return values[field.key];
              return true;
            } else if (field.type === 'number') {
              const value = values[field.key];
              return field?.rules?.isRequired === false
                ? true
                : value !== undefined &&
                    value !== '' &&
                    (field?.rules?.minValue
                      ? value >= field?.rules?.minValue
                      : true) &&
                    (field?.rules?.maxValue
                      ? value <= field?.rules?.maxValue
                      : true);
            } else if (field.type === 'textarea') {
              const value = values[field.key];
              return field?.rules?.isRequired === false
                ? true
                : value !== undefined &&
                    value !== '' &&
                    !/^\s*$/.test(value) &&
                    value.length >= 10 &&
                    value.length <= 150;
            } else if (field.type === 'select') {
              const value = values[field.key];
              return field?.rules?.isRequired === false
                ? true
                : value !== undefined && value !== '';
            } else if (field.type === 'time') {
              const value = values[field.key];
              return field?.rules?.isRequired === false
                ? true
                : value !== undefined && value !== '' && dayjs(value).isValid();
            } else if (field.type === 'switch') {
              const value = values[field.key];
              return field?.rules?.isRequired === false
                ? true
                : value !== undefined;
            } else if (field.type === 'date') {
              const value = values[field.key];
              return field?.rules?.isRequired === false ? true : value;
            } else if (field.type === 'number') {
              const value = values[field.key];
              return field?.rules?.isRequired === false
                ? true
                : value !== undefined && value !== '';
            } else {
              // For headings and other types, consider them as always completed
              return true;
            }
          });
        });
        return isStepCompleted ? step.number : null;
      })
      .filter(step => step !== null);

    // Determine new steps that are now completed
    let newCompletion = _.difference(completed, []);

    // Create a copy of the existing payload
    let newPayload = {...payload};

    // Update payload and timestamp only for newly completed steps
    newCompletion.forEach(stepNumber => {
      const step = formSchema?.steps?.find(s => s.number === stepNumber);
      if (!step) return;

      let stepPayload = {...newPayload[step.key]} || {};
      step?.procedures?.forEach(procedure => {
        procedure?.fields?.forEach(field => {
          if (field.key && field.type !== 'heading' && field.type !== 'text') {
            stepPayload[field.key] =
              field?.type == 'number'
                ? values[field.key]?.includes('.')
                  ? values[field.key]?.split?.[1]?.length <= 2
                    ? parseFloat(values[field.key]).toFixed(2) // Ensure it's treated as a number
                    : values[field.key] // Return original if more than 2 decimals
                  : parseFloat(values[field.key]).toFixed(2)
                : field.type == 'time'
                ? dayjs(values[field.key])
                    .tz(process.env.REACT_APP_TIME_ZONE)
                    .format('HH:mm:A')
                : field.type == 'date'
                ? values[field?.key]
                  ? dayjs(values[field.key])
                      .tz(process.env.REACT_APP_TIME_ZONE)
                      .format('YYYY-MM-DD')
                  : undefined
                : field.type == 'switch'
                ? values[field.key]
                  ? 'True'
                  : 'False'
                : values[field.key];
          }
        });
      });

      let date = new Date().toISOString();
      stepPayload.completedOn =
        payload?.[step?.key]?.completedOn ||
        formPayload?.[step?.key]?.completedOn
          ? payload?.[step?.key]?.completedOn ||
            formPayload?.[step?.key]?.completedOn
          : dayjs(date).tz(process.env.REACT_APP_TIME_ZONE).format(); // This will keep the correct timezone offset

      newPayload[step.key] = stepPayload;
    });

    // Update the payload once after all iterations
    setPayload(newPayload);

    // Set the new completed steps
    setCompletedSteps(completed);

    // Check if any field is filled
    const anyFieldFilled = Object.values(values).some(
      value => value !== undefined && value !== false && value !== '',
    );
    setIsAnyFieldFilled(anyFieldFilled);

    // Set the flag to indicate a step has been completed
    if (newCompletion.length > 0) {
      setIsStepCompleted(true);
    }
  };

  const createPayload = (preserve = true) => {
    let newPayload = preserve ? {...payload} : {}; // Preserve existing payload
    formSchema?.steps?.forEach(step => {
      let stepPayload = {...newPayload[step.key]} || {};

      step?.procedures?.forEach(procedure => {
        procedure?.fields?.forEach(field => {
          if (field.type !== 'heading' && field.type !== 'text') {
            if (!stepPayload[field.key]) {
              stepPayload[field.key] = ''; // Initialize if not already present
            }
          }
        });
      });
      stepPayload['completedOn'] = formPayload?.[step?.key]?.completedOn
        ? formPayload?.[step?.key]?.completedOn
        : stepPayload['completedOn'] || ''; // Preserve existing timestamp
      newPayload[step.key] = stepPayload;
    });
    setPayload(newPayload);
    return newPayload;
  };

  const updatePayloadAndTimestamp = (stepNumber, values) => {
    const step = formSchema?.steps?.find(s => s.number === stepNumber);
    if (!step) return;

    let newPayload = {...payload};
    let stepPayload = {...newPayload[step.key]} || {};
    step?.procedures?.forEach(procedure => {
      procedure?.fields?.forEach(field => {
        if (field.key && field.type !== 'heading' && field.type !== 'text') {
          stepPayload[field.key] =
            field.type == 'time'
              ? dayjs(values[field.key])
                  .tz(process.env.REACT_APP_TIME_ZONE)
                  .format('HH:mm:A')
              : field.type == 'date'
              ? dayjs(values[field.key])
                  .tz(process.env.REACT_APP_TIME_ZONE)
                  .format('YYYY-MM-DD')
              : field.type == 'switch'
              ? values[field.key]
                ? 'True'
                : 'False'
              : values[field.key];
        }
      });
    });
    let date = new Date().toISOString();
    // stepPayload.completedOn = dayjs(date).format('YYYY-MM-DD HH:mm:ss');
    stepPayload.completedOn = date;
    newPayload[step.key] = stepPayload;
    debugger;
    setPayload(newPayload);
  };

  let timeoutId;

  const handleFormValuesChange = () => {
    clearTimeout(timeoutId); // Clear any existing timeout

    timeoutId = setTimeout(() => {
      handleValuesChange();
    }, 2000); // Set new timeout for 3 seconds
  };

  const handleValuesChange = () => {
    if (!uiLoader && !payloadLoader && !techniciansLoader) {
      checkCompletedSteps();
    }
  };
  const onSelectChange = value => {
    setFirstLoad(false);
  };

  const onSelectSearch = value => {};

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const fillObject = (payload, json) => {
    const filledObject = {};
    const steps = Object.keys(payload);
    json?.steps?.forEach(step => {
      if (steps?.includes(step.key)) {
        step.procedures.forEach(procedure => {
          procedure.fields.forEach(field => {
            if (field.name) {
              if (payload[step.key][field.key] !== undefined) {
                filledObject[field.key] =
                  field.type === 'time'
                    ? dayjs(payload[step.key][field.key], 'HH:mm:A')
                    : field.type === 'date'
                    ? payload[step.key][field.key]
                      ? dayjs(payload[step.key][field.key], 'YYYY-MM-DD')
                      : undefined
                    : field.type === 'select'
                    ? payload[step.key][field.key]
                    : // ? payload[step.key][field.key]
                    // : field?.rules?.default
                    field.type === 'switch'
                    ? payload[step.key][field.key] === 'True'
                      ? true
                      : false
                    : field.type === 'checkbox'
                    ? payload[step.key][field.key] === 'True'
                      ? true
                      : false
                    : payload[step.key][field.key];
              }
            }
          });
        });
      }
    });
    return filledObject;
  };

  //HOOKS
  useEffect(() => {
    if (formSchema) {
      form.resetFields();
      checkCompletedSteps(); // Initial check
    }
  }, [form, formSchema]);

  useEffect(() => {
    getTechnicians();
    getRNs();
    getShifts();
    getPayload({
      pathParams: id,
      logic(res) {
        if (res?.response_code === '205') {
          setIsFormCompleted(true);
        }
        if (res?.error === 'Form not found') {
          navigate(-1);
        }
      },
    });
    getTotalChlorineValues();
    getWaterSoftenerValues();
    getMdlResultValues();
    getReasonCodes();
    getSaltLevels();
    getMachineStatus();
    getStatus();
  }, []);

  useEffect(() => {
    if (
      (isOnline ? formPayload : formsData?.[id]) &&
      !payloadLoader &&
      JSON.stringify(formSchema) !== '' &&
      !techniciansLoader
    ) {
      form.setFieldsValue(
        fillObject(isOnline ? formPayload : formsData?.[id], formSchema),
      );
      form.validateFields();
      handleValuesChange();
    }
  }, [
    formPayload,
    payloadLoader,
    formSchema,
    uiLoader,
    technicians,
    techniciansLoader,
  ]);

  useEffect(() => {
    if (formsData?.[id]?.submitted && !isOnline) {
      setIsFormCompleted(true);
    }
  }, [formsData, isOnline]);

  useEffect(() => {
    if (isStepCompleted && !payloadLoader && !firstLoad) {
      submitForm({
        payload: {...payload, Submitted: false},
        pathParams: id,
      });
      setIsStepCompleted(false); // Reset the flag after submission
    }
  }, [payload, isStepCompleted, payloadLoader]);

  useEffect(() => {
    getFormUi({pathParams: id});
  }, []);
  console.log(
    payload,
    firstLoad,
    completedSteps,
    isAnyFieldFilled,
    isStepCompleted,
    isFormCompleted,
    'testing',
  );
  //CUSTOM COMPONENTS
  const renderField = field => {
    switch (field.type) {
      case 'checkbox':
        return (
          <Form.Item name={field.key} valuePropName="checked" key={field.name}>
            <Checkbox onChange={() => setFirstLoad(false)}>
              {field.label}
            </Checkbox>
          </Form.Item>
        );
      case 'number':
        return (
          <Form.Item
            rules={inputFieldRule({
              name: field.name,
              isWhiteSpace: false,
              ...field.rules,
            })}
            name={field.key}
            key={field.name}
            onKeyPress={e => {
              const charCode = e.charCode || e.keyCode;
              const char = String.fromCharCode(charCode);

              // Check if the pressed key is an alphabet character
              if (/^[a-zA-Z]$/.test(char)) {
                e.preventDefault(); // Ignore alphabetic keys
              }
            }}>
            <Input
              className="custom-input text-center"
              type="number"
              placeholder={field?.placeholder}
            />
          </Form.Item>
        );

      case 'textarea':
        return (
          <Form.Item
            rules={inputFieldRule({
              name: field.name,
              isWhiteSpace: true,
              isMaxLength: true,
              isMinLength: true,
              maxLength: 150,
              minLength: 10,
              ...field.rules,
            })}
            name={field.key}
            key={field.name}>
            <TextArea
              className="custom-input"
              placeholder={field?.placeholder}
              maxLength={150}
              minLength={10}
            />
          </Form.Item>
        );
      case 'select':
        return (
          <Form.Item
            rules={inputFieldRule({
              name: field.name,
              isWhiteSpace: false,
              ...field.rules,
            })}
            name={field.key}
            key={field.name}
            style={{width: '100%'}}>
            {(() => {
              const isSpecialField =
                field.url?.includes('shifts') ||
                field.url?.includes('total-chlroine') ||
                field.url?.includes('water-softener') ||
                field.url?.includes('get/result') ||
                field.url?.includes('get/reason-code') ||
                field.url?.includes('get/salt-level') ||
                field.url?.includes('machine_status') ||
                field.url?.includes('status');
              const isAuthorizedField = field.url?.includes('authorized');

              const selectOptions = isSpecialField
                ? field.url?.includes('total-chlroine')
                  ? totalChlorineValues
                  : field.url?.includes('water-softener')
                  ? waterSoftenerValues
                  : field.url?.includes('shifts')
                  ? shifts
                  : field.url?.includes('get/result')
                  ? mdlValues
                  : field.url?.includes('get/reason-code')
                  ? reasonCodes
                  : field.url?.includes('get/salt-level')
                  ? saltLevels
                  : field.url?.includes('machine_status')
                  ? machineStatus
                  : status
                : isAuthorizedField
                ? rnList
                : technicians;

              const selectLoading = isSpecialField
                ? field.url?.includes('total-chlroine')
                  ? totalChlorineValuesLoader
                  : field.url?.includes('water-softener')
                  ? waterSoftenerValuesLoader
                  : field.url?.includes('shifts')
                  ? shiftsLoader
                  : field.url?.includes('get/result')
                  ? mdlValuesLoader
                  : field.url?.includes('get/reason-code')
                  ? reasonCodesLoader
                  : field.url?.includes('get/salt-level')
                  ? saltLevelsLoader
                  : field.url?.includes('machine_status')
                  ? machineStatusLoader
                  : statusLoader
                : isAuthorizedField
                ? rnLoader
                : techniciansLoader;
              return isSpecialField || !isOnline ? (
                <Select
                  allowClear
                  // showSearch
                  optionFilterProp="children"
                  onChange={onSelectChange}
                  onSearch={onSelectSearch}
                  filterOption={filterOption}
                  placeholder={field?.placeholder}
                  options={selectOptions}
                  loading={selectLoading}
                  optionLabelProp="label"
                  // defaultValue={field?.rules?.default ?? null}
                />
              ) : (
                <OTPSelect
                  options={isAuthorizedField ? rnList : technicians}
                  loading={false}
                  placeholder="Select an option"
                  onChange={value => form.setFieldsValue({[field.key]: value})}
                  value={form.getFieldValue(field.key)}
                  field={field}
                  form={form}
                />
              );
            })()}
          </Form.Item>
        );
      case 'time':
        return (
          <Form.Item
            rules={inputFieldRule({
              name: field.name,
              isWhiteSpace: false,
              ...field.rules,
            })}
            name={field.key}
            key={field.name}
            style={{width: '100%'}}>
            <TimePicker
              use12Hours
              format="h:mm:A"
              placeholder={field?.placeholder}
              needConfirm
              onChange={() => setFirstLoad(false)}
            />
          </Form.Item>
        );
      case 'switch':
        return (
          <Form.Item
            name={field.key}
            key={field.name}
            style={{width: '100%'}}
            valuePropName="checked"
            className={`flex items-center justify-${field.align} w-full`}>
            <Switch onChange={() => setFirstLoad(false)} />
          </Form.Item>
        );
      case 'date':
        return (
          <Form.Item name={field.key} key={field.name} style={{width: '100%'}}>
            <DatePicker
              onChange={() => setFirstLoad(false)}
              format={'YYYY-MM-DD'}
            />
          </Form.Item>
        );
      case 'shorttext':
        return (
          <Form.Item
            rules={inputFieldRule({
              name: field.name,
              isWhiteSpace: false,
              ...field.rules,
            })}
            name={field.key}
            key={field.name}>
            <Input
              className="custom-input text-center"
              placeholder={field?.placeholder}
            />
          </Form.Item>
        );

      default:
        return null;
    }
  };

  const handleSubmit = vals => {
    submitForm({
      payload: {...payload, Submitted: true},
      pathParams: id,
      logic(res) {
        toastAlert('Form Submitted Successfully.');
        navigate(-1);
      },
    });
  };

  const getMaxRow = procedures =>
    Math.max(...procedures.flatMap(p => p.fields.map(f => f.row)));

  const getMaxColAll = steps => {
    let maxCol = 0;
    steps.forEach(step => {
      step.procedures.forEach(procedure => {
        procedure.fields.forEach(field => {
          if (field.column > maxCol) {
            maxCol = field.column;
          }
        });
      });
    });
    return maxCol;
  };
  const initialValues = {}; // Create an object to hold initial values

  // Assuming formSchema is structured to access fields
  formSchema?.steps?.forEach(step => {
    step.procedures.forEach(procedure => {
      procedure.fields.forEach(field => {
        if (field?.rules?.default !== undefined) {
          initialValues[field.key] = field?.rules?.default; // Assign initial value
        }
      });
    });
  });
  console.log(initialValues, 'initialValues');
  return (
    <div className="custom-step-from">
      {uiLoader ? (
        <Loader height="80vh" />
      ) : (
        <>
          <div className="flex items-center justify-start py-[11px] bg-white-100 border-b-[1px] border-solid border-grey-200 ">
            {formSchema?.columns?.map((item, index) =>
              item.title === 'Done' ? (
                <div
                  key={index}
                  className={`w-[${item.width}] flex items-center justify-${item.align}`}>
                  <p className="font-h3">
                    {item.title}: {completedSteps?.length} of{' '}
                    {formSchema?.steps?.length}
                  </p>
                </div>
              ) : (
                <div
                  key={index}
                  className={`w-[${item.width}] flex items-center justify-${item.align}`}>
                  <p className="font-h3">{item.title}</p>
                </div>
              ),
            )}
          </div>
          <Form
            form={form}
            layout="vertical"
            onFieldsChange={handleFormValuesChange}
            onKeyUp={() => setFirstLoad(false)}
            className="pb-[90px] relative"
            onFinish={handleSubmit}
            initialValues={initialValues}
            disabled={isFormCompleted}>
            {isFormCompleted && (
              <div className="absolute top-0 left-0 w-full h-full bg-grey-100 z-1 opacity-30"></div>
            )}
            {formSchema?.steps &&
              Array.isArray(formSchema.steps) &&
              [...formSchema.steps] // Create a shallow copy of the array
                .sort((a, b) => a.number - b.number)
                ?.map(step => (
                  <div
                    key={step.id}
                    className="flex items-center justify-start py-[20px] bg-white-100 border-b-[1px] border-solid border-grey-200">
                    <div className={`w-[10%] flex items-center justify-center`}>
                      <p className="font-h3">{step.number}</p>
                    </div>
                    <div className={`w-[73%] flex items-center justify-start`}>
                      <div className="w-full">
                        {step?.visibility ? (
                          <p className="font-h3 mb-[20px]">{step.title}</p>
                        ) : (
                          <></>
                        )}

                        <Row className="w-[100%]" gutter={[0, 10]}>
                          {Array.from({length: getMaxRow(step.procedures)}).map(
                            (_, rowIndex) => (
                              <Col span={24} id={`row-${rowIndex}`}>
                                <Row gutter={[20, 10]}>
                                  {Array.from({
                                    length: getMaxColAll(formSchema.steps),
                                  }).map((_, colIndex) => (
                                    <Col
                                      className="flex items-center"
                                      span={24 / getMaxColAll(formSchema.steps)}
                                      key={`col-${colIndex}`}>
                                      {step.procedures.map(
                                        (procedure, procIndex) => {
                                          return procedure.fields.map(
                                            (field, fieldIndex) => {
                                              if (
                                                field.row === rowIndex + 1 &&
                                                field.column === colIndex + 1
                                              ) {
                                                return (
                                                  <>
                                                    {field.type === 'heading' ||
                                                    field.type === 'text' ? (
                                                      <p
                                                        className={`w-full font-${field?.style?.toLowerCase()} text-${
                                                          field.align
                                                        } mb-[24px]`}>
                                                        {field.text}
                                                      </p>
                                                    ) : (
                                                      renderField(field)
                                                    )}
                                                  </>
                                                );
                                              }
                                              return null;
                                            },
                                          );
                                        },
                                      )}
                                    </Col>
                                  ))}
                                </Row>
                              </Col>
                            ),
                          )}
                        </Row>
                      </div>
                    </div>
                    <div className="w-[17%] flex items-center justify-center">
                      {completedSteps?.includes(step?.number) ? (
                        <Icons.Checked height={24} width={24} />
                      ) : (
                        <Icons.CheckedDisabled height={24} width={24} />
                      )}
                    </div>
                  </div>
                ))}
          </Form>
          <div
            className={`footer fixed bottom-[0] pl-[20px] pr-[60px] lg:pr-[120px] h-[90px] w-full flex items-center ${
              isFormCompleted ? 'justify-between' : 'justify-end'
            } bg-grey-100 gap-[50px] border-t-[1px] border-solid border-grey-200`}>
            {isFormCompleted && (
              <p className="text-[18px] font-semibold  text-[#004078]">
                Today’s log for this form is already submitted.
              </p>
            )}
            <div className="flex items-center justify-end gap-[50px]">
              {/* <Button
                className="custom-form-btn"
                onClick={() => {
                  form.resetFields();
                  createPayload(true);
                  setCompletedSteps([]);
                  setIsAnyFieldFilled(false);
                }}
                disabled={!isAnyFieldFilled || isFormCompleted}>
                Reset All
              </Button> */}
              <Button
                className="custom-form-btn primary"
                disabled={
                  completedSteps?.length !== formSchema?.steps?.length ||
                  isFormCompleted
                }
                onClick={handleSubmit}
                htmlType="submit"
                loading={submitFormLoader}>
                Submit
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StepsFormTest2;
