import {Table, Input} from 'antd';
import {
  SearchOutlined,
  EyeOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons'; // Import the search icon
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Loader} from '../../../components';
import {CustomDispatch} from '../../../helpers';
import {getTodaysLogsRequest} from '../../../redux/slicers/form';
import './styles.scss';

const Submissions = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  let date = new Date().toISOString();
  let updatedDate = dayjs(date)
    .tz(process.env.REACT_APP_TIME_ZONE)
    .format('MMM, DD YYYY');

  const columns = [
    {
      title: 'Date',
      render: () => <p className="font-table">{updatedDate}</p>,
    },
    {
      title: 'Name',
      dataIndex: 'form_name',
      render: text => <p className="font-normal">{text}</p>,
      sorter: (a, b) => a.form_name.localeCompare(b.form_name),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: text => <p className="font-table">{text}</p>,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Action',
      render: (_, record) => (
        <div className="flex items-center justify-start gap-2">
          <EyeOutlined className="cursor-pointer" />
          <LockOutlined className="cursor-pointer" />
        </div>
      ),
    },
  ];

  // Redux data
  const {todaysLogs} = useSelector(state => state.form);
  const {data} = useSelector(state => state.user);

  // Custom dispatch
  const [getTodaysLogs, isLoading] = CustomDispatch(getTodaysLogsRequest);

  // Hooks
  useEffect(() => {
    if (data?.id) getTodaysLogs();
  }, [data]);

  // Handle edit function
  const handleEdit = id => {};

  // Filtered data based on search query
  const filteredData =
    todaysLogs?.filter(log =>
      log.form_name.toLowerCase().includes(searchQuery.toLowerCase()),
    ) ?? [];

  return (
    <section className="submissions mt-[69px]">
      <div className="h-[50px] bg-grey-100 border-b-[1px] border-solid border-grey-200 justify-end flex items-center px-2"></div>
      {isLoading ? (
        <Loader height="80vh" />
      ) : (
        <div className="dashboard-content px-[20px] md:px-[40px] py-[20px]">
          <p className="font-arial font-medium text-xl text-textColor-100 mb-[30px]">
            Today's Logs
          </p>
          <Input
            placeholder="Search by form name"
            prefix={<SearchOutlined />}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            className="search-input mb-2"
          />
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            className="search-table"
            scroll={{x: 530}}
          />
        </div>
      )}
    </section>
  );
};

export default Submissions;
