import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Icons} from '../../../theme';
import './styles.scss';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {StepsForm, StepsFormTest} from '../../../components';
import formData from '../../../components/private/commonForm/formSchema.json';
import {useSelector} from 'react-redux';
import StepsFormTest2 from '../../../components/private/commonFormTest2';
const FormPageTest = () => {
  //CONST VALS
  const navigate = useNavigate();

  const {id} = useParams();

  //REDUX DATA
  const {formPayload} = useSelector(state => state.form);

  return (
    <section className="form-page  relative ">
      <div className="absolute top-[0] w-full flex items-center justify-between pl-7 pr-20 py-[11px] bg-grey-100 border-b-[1px] border-solid border-grey-200 z-[1] ">
        <div className="flex items-center justify-start gap-[25px]">
          <Icons.LeftChevron
            className="cursor-pointer icon"
            onClick={() => navigate(-1)}
          />
          <p className="font-h2 uppercase ">
            {formPayload?.name ? formPayload?.name : ''}
          </p>
        </div>
        <div className="flex items-center justify-start gap-[15px]">
          <div className="flex items-center justify-start gap-[10px] cursor-pointer form-header-options disabled">
            <Icons.ExportIcon height={24} width={24} />
            <p className="font-roboto font-medium text-lg text-blue-300">
              Export
            </p>
          </div>
          <div className="flex items-center justify-start gap-[10px] cursor-pointer form-header-options disabled">
            <Icons.PrintIcon height={24} width={24} />
            <p className="font-roboto font-medium text-lg text-blue-300">
              Print
            </p>
          </div>
        </div>
      </div>
      <StepsFormTest2 />
    </section>
  );
};

export default FormPageTest;
