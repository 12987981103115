import React from 'react';
import Icons from '../../../../theme/icons';
import './styles.scss';
function ProcedureCards({title, description, onClick}) {
  return (
    <div className="card-wrapper p-[15px] border-[1px]  border-solid border-grey-200 rounded-[3px] h-[175px]  hover:border-blue-200">
      <div className="flex items-center justify-start gap-[10px] mb-[20px]">
        <Icons.ProcedureIcon width={30} height={30} />
        <p
          onClick={onClick}
          className="font-h4 text-blue-300 hover:text-blue-200 cursor-pointer">
          {title}
        </p>
      </div>
      <p className="font-paragraph">{description}</p>
    </div>
  );
}

export default ProcedureCards;
